#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* Animations */
@keyframes table-bg-fade {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #eeeeee;
  }
  100% {
    background-color: #ffffff;
  }
}
.table-loading {
  animation-name: table-bg-fade;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinning {
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.pdf-viewer-page {
  border: 1px solid #eeeeee;
}
/* Target autofilled input fields */
input:-webkit-autofill {
  background-color: #f0f8ff !important;
  /* Use a nicer color, e.g., Alice Blue */
  color: #000 !important;
  /* Optional: set text color */
  border: 1px solid #ccc;
  /* Optional: customize the border */
  -webkit-text-fill-color: #000 !important;
  /* Ensure text color is applied */
  box-shadow: 0 0 0px 1000px #f0f8ff inset !important;
  /* Force background color */
}
